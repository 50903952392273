import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  CalendarFiltersData,
  CalendarPageResource, EducationMasterGroupResource,
  ManagerCalendarFiltersGetRequest,
  ManagerCalendarFiltersPostRequest,
  ManagerCalendarGetParams,
  ManagerCalendarGetRequest,
  ManagerMasterGroupsMasterGroupIdCalendarGetParams,
  ManagerMasterGroupsSelectGetParams,
  ManagerMasterGroupsSelectGetRequest,
} from '@/store/types/schema'
import {
  CalendarLargeResource, IManagerCalendarFilter,
  ManagerMasterGroupsMasterGroupIdCalendarGetRequest,
} from '@/store/types'

/**
 * Календарь наставника
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MentorCalendar',
  namespaced: true,
  store,
})
class MentorCalendar extends VuexModule {
  // ---------------------------- Mentor Calendar ---------------------------- >>
  calendar: CalendarLargeResource | null = null
  fullCalendar: CalendarPageResource | null = null
  calendarFilter: IManagerCalendarFilter = {
    endAt: '',
    exerciseStatus: null,
    lessonStatus: null,
    startAt: '',
  }
  masterGroupsSelect: EducationMasterGroupResource[] = []

  @Mutation
  setCalendar(payload: CalendarLargeResource) {
    this.calendar = payload
  }

  @Mutation
  setFullCalendar(payload: CalendarPageResource) {
    this.fullCalendar = payload
  }

  @Mutation
  setCalendarFilter(payload: IManagerCalendarFilter) {
    this.calendarFilter = Object.assign({}, payload)
  }

  @Mutation
  setMasterGroupSelect(payload: EducationMasterGroupResource[]) {
    this.masterGroupsSelect = payload
  }

  @Action({ rawError: true })
  async fetchCalendar(payload: { masterGroupId: number, params: ManagerMasterGroupsMasterGroupIdCalendarGetParams }) {
    const { data } = await ManagerMasterGroupsMasterGroupIdCalendarGetRequest(payload.masterGroupId, payload.params)
    this.setCalendar(data)
    return data
  }

  @Action({ rawError: true })
  async fetchFullCalendar(payload: ManagerCalendarGetParams) {
    const { data } = await ManagerCalendarGetRequest(payload)
    this.setFullCalendar(data)
    return data
  }

  @Action({ rawError: true })
  async fetchMentorCalendarFilter(){
    const { data } = await ManagerCalendarFiltersGetRequest()
    return data
  }

  @Action({ rawError: true })
  async saveCalendarFilters(payload: CalendarFiltersData) {
    const { data } = await ManagerCalendarFiltersPostRequest(payload)
    return data
  }

  @Action({ rawError: true })
  async fetchMasterGroups(payload?: ManagerMasterGroupsSelectGetParams) {
    const { data } = await ManagerMasterGroupsSelectGetRequest(payload, { loading: false })
    this.setMasterGroupSelect(data)
    return data
  }
}

const MentorCalendarModule = getModule(MentorCalendar)

export default MentorCalendarModule

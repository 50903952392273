import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators'

import store from '@/store'
import {
  CalendarFiltersData,
  CalendarLargeResource, CalendarPageResource,
  MasterEducationCalendarFiltersGetRequest, MasterEducationCalendarFiltersPostRequest,
  MasterEducationCalendarGetParams,
  MasterEducationCalendarGetRequest,
  MasterEducationMasterGroupIdCalendarGetParams,
  MasterEducationMasterGroupIdCalendarGetRequest,
} from '@/store/types'

/**
 * Модуль для работы с календарем мастера
 * - todo
 */

@Module({
  dynamic: true,
  name: 'MasterCalendar',
  namespaced: true,
  store,
})
class MasterCalendar extends VuexModule {
  // ---------------------------- Master Calendar ---------------------------- >>
  calendar: CalendarLargeResource | null = null
  fullCalendar: CalendarPageResource | null = null
  calendarFilter: MasterEducationMasterGroupIdCalendarGetParams = {
    endAt: '',
    startAt: '',
  }

  @Mutation
  setCalendar(payload: CalendarLargeResource) {
    this.calendar = payload
  }

  @Mutation
  setFullCalendar(payload: CalendarPageResource) {
    this.fullCalendar = payload
  }

  @Mutation
  setCalendarFilter(payload: MasterEducationMasterGroupIdCalendarGetParams) {
    this.calendarFilter = Object.assign({}, payload)
  }

  @Action({ rawError: true })
  async fetchCalendar(payload: { masterGroupId: number, params: MasterEducationMasterGroupIdCalendarGetParams }) {
    const { data } = await MasterEducationMasterGroupIdCalendarGetRequest(payload.masterGroupId, payload.params)
    this.setCalendar(data)
    return data
  }

  @Action({ rawError: true })
  async fetchFullCalendar(payload: MasterEducationCalendarGetParams) {
    const { data } = await MasterEducationCalendarGetRequest(payload)
    this.setFullCalendar(data)
    return data
  }

  @Action({ rawError: true })
  async fetchMasterCalendarFilters() {
    const { data } = await MasterEducationCalendarFiltersGetRequest()
    return data
  }

  @Action({ rawError: true })
  async saveCalendarFilters(payload: CalendarFiltersData) {
    const { data } = await MasterEducationCalendarFiltersPostRequest(payload)
    return data
  }
}

const MasterCalendarModule = getModule(MasterCalendar)

export default MasterCalendarModule
